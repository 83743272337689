import axios from 'axios'
//import CancelStore from '@/axios/store';
import { message } from 'ant-design-vue';
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = ''
}
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'
// let baseURL = "http://api.iptzx.com/api";
// if (process.env.NODE_ENV === "development") {
//   baseURL = process.env.baseURL;
// } else {
//   baseURL = process.env.baseURL;
// }

// axios.defaults.timeout = 30000;
// axios.defaults.retry = 1;
// axios.defaults.retryDelay = 1000;


//const CancelToken = axios.CancelToken;


// 创建axios实例
const request = axios.create({
    timeout: 30000 // 请求超时时间
})

// request拦截器
request.interceptors.request.use(
    config => {
        return config
    },
    error => {
        console.log(error) // for debug 11
        Promise.reject(error)
    }
)

// response 拦截器
request.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    },
    error => {
        message.error({
            content: error.message,
            duration: 3000
        })
        return Promise.reject(error)

    }
)

export default request
