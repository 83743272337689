import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Authentication from '../views/Authentication.vue'
// import mobileHome from '../views/mobileHome.vue'
// import mobileAuthentication from '../views/mobileAuthentication.vue'
// import payGateWay from '../views/payGateWay.vue'
// import paySuccess from '../views/paySuccess.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  { path: "/", redirect: "/Home" },
  {
    path: "/Home", // pc端首页
    name: "Home",
    component: () => import('@/views/HomeContainer')
  },
  {
    path: "/Home/Authentication",
    component: () => import('../views/Authentication.vue'),
  },
  {
    path: "/mobileHome/mobileAuthentication",
    component: () => import('../views/mobileAuthentication.vue'),
  },
  {
    path: "/payGateWay",
    component: () => import('../views/payGateWay.vue'),
  },
  {
    path: "/paySuccess", component: () => import('../views/paySuccess.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
