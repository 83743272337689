<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  mounted() {
    // 友盟统计添加
    const script = document.createElement("script")
    script.src =
      "https://s4.cnzz.com/z_stat.php?id=1281082690&web_id=1281082690" //该路径为点击统计代码后，页面中第一个 文字统计 script代码
    script.language = "JavaScript"
    document.body.appendChild(script)
  },
  watch: {
    $route() {
      if (window._czc) {
        let location = window.location
        let contentUrl = location.pathname + location.hash
        let refererUrl = "/"
        window._czc.push(["_trackPageview", contentUrl, refererUrl])
      }
    },
  },
}
</script>

<style lang="less">
#app {
  overflow-x: hidden;
}
.ant-steps-item-container {
  width: 200px;
}
.ant-carousel {
  width: 100%;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #0a49ff !important;
}
.ant-carousel .slick-dots li button {
  background: #eee !important;
}

// .ant-form-item {
//   // margin-right: 120px;
// }
.ant-col-sm-6 {
  width: 20%;
}
</style>
